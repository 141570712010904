import { styled } from "styled-components";

import TextBlock from "components/TextBlock";
import { styleBodyM } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

export const Text = styled(TextBlock)`
    grid-column: col-start 1 / span 2;
    margin-top: 0.875rem;
    width: 100%;

    ${MQ.FROM_M} {
        grid-column: auto / span 6;
        margin-top: 1.3125rem;
    }

    ${MQ.FROM_XL} {
        margin-top: 1.75rem;
    }

    &:first-child {
        margin-top: 0;
    }

    p {
        ${styleBodyM};
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    u:has(> a) {
        text-decoration: none;
    }
`;

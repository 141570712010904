import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import { addEditAttributes } from "utils/episerver";
import evaluteVisibilityFromDependencies from "utils/evaluteVisibilityFromDependencies";

import { Text } from "./FormParagraphText.styled";
import FormParagraphTextProps from "./FormParagraphTextProps";

const FormParagraphText = ({
    identifier,
    paragraphText,
    dependencies,
    control,
}: FormParagraphTextProps): ReactElement => {
    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;

    const themeContext = useContext(ThemeContext);

    return (
        <ThemeProvider
            theme={{
                ...themeContext,
            }}
        >
            {visibleFromDependencies && (
                <Text
                    identifier={identifier}
                    content={paragraphText}
                    areaName="paragraphText"
                    {...addEditAttributes("ParagraphText")}
                />
            )}
        </ThemeProvider>
    );
};

export default React.memo(FormParagraphText);
